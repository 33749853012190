.work-brief {
  margin: 0px auto 40px;
}

.work-examples {
  background-color: #060C18;
  color: #fff;
  padding-bottom: 40px;
  margin-bottom: 70px;
}

.work-examples .work-brief {
  margin: 0px auto 0px;
}

.work-brief--black {
  color: #fff;
  background: #060C18;
  padding: 50px 0px;
}

.work-examples .work-brief--black {
  padding: 50px 0px 70px;
}

.work-brief__inner {
  box-sizing: border-box;
  display: flex;
  padding: 0px 20px;
}

.work-brief img {
  display: block;
  width: 100%;
  max-width: 100%;
  border-radius: 5px;
  margin: 0px auto;
}

.work-brief__title {
  font-size: 32px;
  line-height: 41px;
  font-weight: 700;
  margin: 0px 0px 10px;
}

.work-brief__category {
  font-size: 16px;
  line-height: 21px;
  font-weight: 800;
  background: linear-gradient(270deg, #57310D 0%, #CB7921 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
}

.work-brief__info {
  width: 100%;
  max-width: 620px;
  flex-shrink: 0;
  box-sizing: border-box;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
}

.work-brief__text {
  margin-top: 30px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;
  max-width: 90%;
}

.work-brief__details {
  display: flex;
  margin: auto auto 0px;
  padding-top: 20px;
}

.work-brief__detail {
  width: 100%;
  max-width: 200px;
  text-align: center;
}

.work-brief__detail-head {
  font-weight: 600;
  font-size: 28px;
  line-height: 1.3;
  margin-bottom: 10px;
}

.work-brief__detail-text {
  font-size: 18px;
  line-height: 1.3;
  font-weight: 400;
}

.splide--our-work {
  position: relative;
}

.splide--our-work .splide__arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splide--our-work .splide__arrow {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 300ms linear;
}

.splide--our-work .splide__arrow:focus,
.splide--our-work .splide__arrow:hover {
  opacity: 1;
}

.splide--our-work .splide__arrow--prev {
  transform: scale(-1, 1);
}

.splide--our-work.work-examples__slider > .splide__arrows {
  padding: 0px;
}

.splide--our-work.work-examples__slider > .splide__arrows .splide__arrow--prev {
  margin-left: -65px;
}

.splide--our-work.work-examples__slider > .splide__arrows .splide__arrow--next {
  margin-right: -65px;
}

.splide--our-work .splide__pagination {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.splide--our-work .splide__pagination li {
  margin-right: 10px;
}

.splide--our-work .splide__pagination li:last-of-type {
  margin-right: 0px;
}

.splide--our-work .splide__pagination__page {
  display: block;
  width: 15px;
  height: 15px;
  padding: 0px;
  background: none;
  border-radius: 50%;
  background: linear-gradient(270deg, rgba(87, 49, 13, 0.3) 0%, rgba(203, 121, 33, 0.3) 100%);
  border: 1px solid #CB7921;
}

.splide--our-work .splide__pagination__page.is-active {
  background: linear-gradient(270deg, #57310D 0%, #CB7921 100%);
  border: none;
  opacity: 1;
}

@media screen and (max-width: 1420px) {
  .work-examples .work-brief__inner {
    padding: 0px 60px;
  }

  .splide--our-work.work-examples__slider > .splide__arrows .splide__arrow--prev {
    margin-left: -18px;
  }

  .splide--our-work.work-examples__slider > .splide__arrows .splide__arrow--next {
    margin-right: -18px;
  }
}

@media screen and (max-width: 1320px) {
  .work-brief__title {
    font-size: 32px;
    line-height: 41px;
  }

  .work-brief__category {
    font-size: 15px;
    line-height: 20px;
  }

  .work-brief__text {
    margin-top: 20px;
  }

  .work-brief__detail {
    padding: 0px 10px;
  }

  .work-brief__detail-head {
    font-size: 24px;
    margin-bottom: 6px;
  }

  .work-brief__detail-text {
    font-size: 16px;
  }

  .work-examples .work-brief__info {
    max-width: 450px;
    padding-right: 15px;
  }

  .work-examples .work-brief__inner {
    padding: 0px 80px;
  }

  .splide--our-work.work-examples__slider > .splide__arrows .splide__arrow--prev {
    margin-left: 0px;
  }

  .splide--our-work.work-examples__slider > .splide__arrows .splide__arrow--next {
    margin-right: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .work-brief__inner {
    flex-direction: column;
  }

  .work-brief--black {
    padding: 30px 0px;
  }

  .work-brief__info {
    max-width: 100%;
    padding: 0px;
    margin: 0px auto 25px;
  }

  .work-brief__text {
    max-width: 100%;
    margin-top: 10px;
  }

  .work-brief__detail-head {
    margin-bottom: 4px;
  }

  .work-examples {
    padding: 0px 20px 20px;
  }

  .work-examples .work-brief__inner {
    padding: 0px;
  }

  .splide--our-work.work-examples__slider > .splide__arrows {
    display: none;
  }

  .work-examples .work-brief__info {
    max-width: 100%;
  }

  .work-examples .read-more-wrapper {
    text-align: right;
    margin-top: 20px;
  }

  .work-examples .work-brief__info {
    padding: 0px;
  }
}

@media screen and (max-width: 768px) {
  .our-works__title {
    margin-bottom: 30px;
    text-align: left;
    padding: 0px 20px;
  }

  .work-brief__title {
    font-size: 22px;
    line-height: 28px;
  }

  .work-brief__detail-head {
    font-size: 15px;
  }

  .work-brief__detail-text {
    font-size: 12px;
  }

  .work-brief__text {
    font-size: 15px;
    line-height: 1.5;
  }

  .work-brief__detail {
    padding: 0px 1px;
  }

  .work-brief .splide__arrow img {
    max-width: 60px;
  }

  .work-brief__category {
    font-size: 14px;
  }

  .splide--our-work .splide__pagination__page {
    width: 12px;
    height: 12px;
  }

  .work-examples .work-brief__inner {
    padding: 0px 0px 50px;
  }

  .work-examples .work-brief--black {
    padding: 50px 0px 0px;
  }

  .work-examples__slider .work-brief img {
    max-width: 360px;
  }
}