.header-slider {
  position: relative;
  margin-top: 88px;
  color: #fff;
  padding: 0px 100px;
}

.header-slider__arrows {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header-slider__arrow {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 300ms linear;
}

.header-slider__arrow:focus,
.header-slider__arrow:hover {
  opacity: 1;
}

.header-slider__arrow--prev {
  transform: scale(-1, 1);
}

.header-slider .splide__pagination li + li {
  margin-left: 10px;
}

.header-slider .splide__pagination__page {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #57310D;
  background: linear-gradient(270deg, rgba(87, 49, 13, 0.3) 0%, rgba(203, 121, 33, 0.3) 100%);
  opacity: 0.85;
  transition: opacity 200ms linear;
}

.header-slider .splide__pagination__page:focus,
.header-slider .splide__pagination__page:hover {
  opacity: 1;
}

.header-slider .splide__pagination__page.is-active {
  background: linear-gradient(270deg, #57310D 0%, #CB7921 100%);
  border: none;
  opacity: 1;
}

.header-slider__arrow img {
  display: block;
}

.header-slider .splide__slide {
  padding-bottom: 50px;
}

.common-btn--header-slider {
  font-size: 18px;
  padding: 15px 25px;
  margin-top: 50px;
}

@media screen and (max-width: 1024px) {
  .header__content {
    padding: 0px 20px;
  }

  .header-slider {
    margin-top: 130px;
    padding: 0px 0px;
  }

  .header-slider .splide__slide {
    padding-bottom: 70px;
  }

  .common-btn--header-slider {
    font-size: 16px;
  }

  .header-slider__arrows {
    top: 100%;
  }
}

@media screen and (max-width: 768px) {
  .header-slider {
    margin-top: 45px;
  }

  .common-btn--header-slider {
    margin-top: 45px;
  }

  .header-slider__arrows {
    transform: translateY(-10px);
  }

  .header-slider__arrow img {
    max-width: 60px;
  }

  .header-slider .splide__pagination li + li {
    margin-left: 8px;
  }
}