@import url(./assets/css/normalize.css);
@import url(./assets/css/fonts.css);
@import url(./assets/css/common.css);
@import url(./assets/css/header.css);
@import url(./assets/css/header-slider.css);
@import url(./assets/css/menu.css);
@import url(./assets/css/service.css);
@import url(./assets/css/benefits.css);
@import url(./assets/css/service-catalog.css);
@import url(./assets/css/partners.css);
@import url(./assets/css/feedback-info.css);
@import url(./assets/css/footer.css);
@import url(./assets/css/socials.css);
@import url(./assets/css/header-company-stats.css);
@import url(./assets/css/form-popup.css);

/* Contacts */
@import url(./assets/css/contacts.css);

/* About Us */
@import url(./assets/css/about-us.css);

/* Out Work */
@import url(./assets/css/our-work.css);
@import url(./assets/css/work-brief.css);

/* Product Page */
@import url(./assets/css/product-content.css);
@import url(./assets/css/product-catalog.css);
@import url(./assets/css/product-box.css);
@import url(./assets/css/breadcrumbs.css);