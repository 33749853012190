.breadcrumbs {
  margin-top: 40px;
}

.breadcrumbs__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
}

.breadcrumbs__list li {
  margin: 3px 0px;
}

.breadcrumbs__item {
  color: #828282;
  transition: color 100ms linear;
  font-size: 14px;
}

.breadcrumbs__item::after {
  content: '/';
  display: inline-block;
  margin: 0px 3px;
}

.breadcrumbs__item:focus,
.breadcrumbs__item:hover {
  color: #CB7921;
}

.breadcrumbs__item.active {
  color: #CB7921;
}

.breadcrumbs__item.active::after {
  display: none;
}

@media screen and (max-width: 1320px) {
  .breadcrumbs {
    margin-top: 70px;
  }
}

@media screen and (max-width: 768px) {
  .breadcrumbs {
    margin-top: 40px;
  }
}