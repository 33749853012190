.product-content {
  padding: 100px 0px 70px;
}

.product-content__image {
  display: block;
  float: left;
  margin: 0px 20px 20px 0px;
  max-width: 100%;
}

.product-title {
  font-size: 38px;
  line-height: 50px;
  margin-bottom: 40px;
  margin-top: 0px;
}

.product-content,
.product-content p {
  font-size: 18px;
  line-height: 24px;
  margin-top: 0px;
  margin-bottom: 20px;
}

.product-content {
  margin-bottom: 0px;
}

.product-content ul,
.product-content ol {
  margin: 4px 0px 20px;
  list-style: disc;
  list-style-position: inside;
  padding-left: 5px;
  text-indent: 8px;
}

.product-content ol {
  list-style: decimal;
  list-style-position: inside;
}

@media screen and (max-width: 1320px) {
  .product-content__image-wrapper {
    max-width: 500px
  }
}

@media screen and (max-width: 1024px) {
  .product-content {
    box-sizing: border-box;
    padding: 60px 20px 55px;
  }

  .product-content__image-wrapper {
    display: none;
  }

  .product-title {
    font-size: 36px;
    line-height: 47px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .product-title {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 25px;
  }

  .product-content > div {
    box-sizing: border-box;
    padding: 0px 20px;
  }

  .product-content,
  .product-content p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .product-content {
    padding: 50px 0px;
    margin-bottom: 0px;
  }
}