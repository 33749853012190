.header-company-stats {
  display: flex;
  margin: 40px 0px 0px;
}

.header-company-stats__item {
  width: 100%;
  text-align: center;
  color: #fff;
  max-width: 200px;
}

.header-company-stats__item + .header-company-stats__item {
  margin-left: 20px;
}

.header-company-stats__title {
  display: block;
  font-size: 32px;
  line-height: 42px;
  font-weight: 500;
  color: #CB7921;
}

.header-company-stats__desc {
  font-size: 18px;
  line-height: 23px;
}

@media screen and (max-width: 1320px) {
  .header-company-stats {
    margin: 60px 0px 0px;
  }
}

@media screen and (max-width: 1024px) {
  .header-company-stats {
    margin: 40px 0px 0px;
  }
}

@media screen and (max-width: 768px) {
  .header-company-stats__item {
    max-width: unset;
  }

  .header-company-stats__title {
    font-size: 22px;
  }

  .header-company-stats__desc {
    font-size: 12px;
    line-height: 1.3;
  }

  .header-company-stats__item + .header-company-stats__item {
    margin-left: 14px;
  }
}