.about-us {
  box-sizing: border-box;
  padding: 70px 0px 0px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}

.about-us > .normal-content-size {
  box-sizing: border-box;
  padding: 0px 20px;
}

.about-us__row {
  display: flex;
  margin-bottom: 50px;
}

.about-us__cell {
  width: 100%;
  margin-right: 20px;
}

.about-us__title {
  font-size: 32px;
  line-height: 42px;
  margin: 0px auto 30px;
  text-align: center;
}

.about-us__text--before-list {
  margin-bottom: 10px;
}

.about-us__text--list {
  margin: 0px;
  list-style: disc;
  padding-left: 30px;
}

.about-us__text--list li {
  margin-bottom: 4px;
}

.about-us__content-picture {
  width: 100%;
  max-width: 100%;
}

.about-us__pictures-wrapper {
  margin-bottom: 70px;
}

.section-title--about-us {
  margin-top: 70px;
  line-height: 1.3;
  margin-bottom: 35px;
}

.licenses {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0px auto 70px;
}

.licenses__picture {
  display: block;
  width: calc(50% - 10px);
  max-width: 100%;
}

.licenses__picture + .licenses__picture {
  margin-left: 20px;
}

@media screen and (max-width: 1320px) {
  .licenses__picture {
    width: 100%;
    max-width: 49%;
  }
}

@media screen and (max-width: 1024px) {
  .about-page--about-us .about-page-desc {
    font-size: 18px;
    line-height: 24px;
  }

  .about-us {
    padding: 40px 0px 0px;
  }

  .about-page--about-us {
    margin-top: 45px;
  }

  .about-us__row {
    display: block;
  }

  .about-us__pictures-wrapper {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 768px) {
  .licenses {
    flex-direction: column;
    align-items: center;
  }

  .licenses__picture {
    max-width: 100%;
  }

  .licenses__picture + .licenses__picture {
    margin-top: 20px;
    margin-left: 0px;
  }

  .about-page--about-us .about-page-desc {
    font-size: 16px;
    line-height: 21px;
  }

  .about-us {
    padding: 20px 0px 0px;
    font-size: 16px;
    line-height: 1.3;
  }

  .about-us__row {
    margin-bottom: 30px;
  }

  .about-us__title {
    font-size: 23px;
    line-height: 1.3;
  }

  .section-title--about-us {
    margin-top: 40px;
  }

  .licenses {
    margin-bottom: 50px;
  }
}