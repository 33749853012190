.form-popup {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2b2b2bb0;
  z-index: 20;
  display: none;
}

.form-popup.show {
  display: flex;
}

.form-popup__inner {
  position: relative;
  box-sizing: border-box;
  padding: 30px 40px 35px;
  background: #fff;
  max-width: 700px;
}

.form-popup__title {
  text-align: center;
  padding-right: 0px;
  font-size: 29px;
  line-height: 1.3;
}

.form-popup .feedback-form {
  max-width: 100%;
}

.form-popup .feedback-form__inner {
  justify-content: center;
}

.form-popup .feedback-form__text {
  max-width: 100%;
  text-align: center;
}

.form-popup .feedback-form__field {
  margin-right: 8px;
}

.form-popup .feedback-form__field:last-child {
  margin-right: 0px;
}

.form-popup__close-btn {
  position: absolute;
  width: 36px;
  height: 36px;
  top: -18px;
  right: -18px;
  box-shadow: 0px 0px 5px #434343;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  padding: 0px;
  background: url('../images/close-menu-icon.svg') no-repeat center / 46%;
  background-color: #2b2b2b;
  transition: opacity 300ms linear, transform 300ms linear;
}

.form-popup__close-btn:focus,
.form-popup__close-btn:hover {
  opacity: 0.7;
  transform: scale(1.1);
}

@media screen and (max-width: 1024px) {
  .form-popup__close-btn {
    right: 2px;
    top: -41px;
  }
}

@media screen and (max-width: 768px) {
  .form-popup__inner {
    padding: 20px 20px 5px;
    max-width: 95%;
    margin: 0px auto;
  }

  .form-popup__title {
    font-size: 24px;
  }

  .feedback-form__text {
    font-size: 16px;
    line-height: 1.3;
  }

  .form-popup .feedback-form__inner {
    margin-top: 22px;
  }

  .form-popup__close-btn {
    border: 1px solid #b5b5b5;
  }
}