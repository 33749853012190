.footer {
  background-color: #060c18;
  padding: 70px 0px 50px;
}

.footer__top {
  padding-bottom: 40px;
  border-bottom: 1px solid #fff;
}

.footer__contacts {
  max-width: 490px;
  color: #fff;
}

.footer__top--black-text .footer__contacts,
.footer__top--black-text .company-phone,
.footer__top--black-text .company-address,
.footer__top--black-text .company-email,
.footer__top--black-text .phone-feedback__title,
.footer__top--black-text .parsing-data-agree--footer {
  color: #2B2B2B;
}

.footer__contact-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 1.3;
  margin: 0px auto 20px;
}

.footer__contant-text {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

.footer-text-highlight {
  color: #cb7921;
}

.footer__map-image {
  display: block;
  max-width: 100%;
  border-radius: 4px;
}

.footer__address,
.footer__email {
  margin-bottom: 24px;
  max-width: 340px;
  font-weight: 500;
}

.footer__address::before,
.footer__email::before {
  margin-right: 10px;
}

.footer__email {
  margin-bottom: 15px;
}

.footer__phone-number {
  display: block;
  font-size: 20px;
  line-height: 24px;
}

.footer__phone-number::before {
  width: 36px;
  height: 36px;
  background: url('../images/gradient-phone.png');
}

.footer__address {
  display: flex;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
}

.footer__address::before {
  content: "";
  display: inline-block;
  width: 36px;
  height: 36px;
  background: url('../images/gradient-location.png') no-repeat center / contain;
  flex-shrink: 0;
}

.footer__bottom {
  margin-top: 40px;
}

.footer__bottom-row {
  display: flex;
}

.footer__site-nav {
  display: flex;
}

.footer_services-links,
.footer_about-us-links {
  margin: 0px;
}

.footer_about-us-links {
  margin-left: 58px;
}

.footer__bottom-row--first {
  margin-bottom: 40px;
}

.footer_services-links li,
.footer_about-us-links li {
  display: block;
  margin-bottom: 10px;
}

.footer_services-links li:last-of-type,
.footer_about-us-links li:last-of-type {
  margin-bottom: 0px;
}

.footer_services-links a,
.footer_about-us-links a {
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
}

.footer__bottom-left-part {
  width: 100%;
  max-width: 520px;
  margin-right: 20px;
}

.footer__logo-link {
  display: inline-block;
}

.footer_services-links a {
  display: inline-block;
}

.footer__company-full-name {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 30px;
  margin-top: 0px;
}

.footer__contacts-map {
  display: flex;
  justify-content: space-between;
}

.footer__bottom-row--second {
  color: #fff;
}

.footer__map {
  border-radius: 5px;
  overflow: hidden;
}

.footer__map iframe {
  max-width: 100%;
}

@media screen and (max-width: 1320px) {
  .footer__contact-title {
    font-size: 32px;
  }

  .footer__contant-text {
    font-size: 20px;
  }

  .footer__phone-number {
    font-size: 18px;
  }

  .footer__bottom-left-part {
    max-width: 420px;
  }

  .footer__logo-image {
    max-width: 240px;
  }

  .footer__map iframe {
    height: 400px !important;
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    box-sizing: border-box;
    padding: 70px 20px 50px;
  }

  .footer__contacts {
    max-width: 385px;
    padding-right: 30px;
    box-sizing: border-box;
  }

  .footer__contant-text {
    font-size: 18px;
  }

  .footer__logo-image {
    max-width: 167px;
    margin-right: 80px;
  }

  .footer__bottom-left-part {
    width: auto;
    max-width: none;
    margin: 0px;
  }

  .footer_services-links a, .footer_about-us-links a {
    font-size: 14px;
  }

  .footer_about-us-links {
    margin-left: 40px;
  }

  .footer_services-links a {
    display: flex;
    align-items: center;
  }

  .footer_services-links .drop-item::after {
    margin-top: 0px;
    margin-bottom: 2px;
  }
}

@media screen and (max-width: 768px) {
  .footer__top {
    padding-bottom: 30px;
  }

  .footer__contacts-map {
    display: block;
  }

  .footer__contacts {
    max-width: 700px;
    margin: 0px auto;
  }

  .footer__map {
    margin-top: 30px;
  }

  .footer__logo-image {
    max-width: 65px;
    margin-right: 20px;
  }

  .footer__site-nav {
    flex-direction: column;
  }

  .footer_about-us-links {
    margin-left: 0px;
    margin-top: 15px;
  }

  .footer__bottom-row--first {
    justify-content: center;
  }

  .footer_services-links .drop-item::after {
    margin-top: -2px;
  }

  .footer__bottom-row--second {
    flex-direction: column;
  }

  .footer__company-full-name {
    font-size: 14px;
  }

  .footer__contact-title {
    font-size: 28px;
  }

  .footer__contant-text {
    line-height: 1.5;
  }

  .footer__address {
    line-height: 1.4;
  }

  .footer__company-full-name {
    margin-bottom: 20px;
  }

  .footer__map iframe {
    height: 300px !important;
  }
}