.header {
  box-sizing: border-box;
  background: linear-gradient(to bottom, #050B17 49.93%, rgba(5, 11, 23, 0) 99.41%), url(../images/header-bg.jpg) no-repeat right bottom;
  background-color: black;
  background-position: bottom right;
  min-height: 800px;
  padding-bottom: 40px;
}

.header__top {
  padding: 7px 0px 48px;
  display: flex;
  align-items: center;
}

.header__nav-primary-links,
.header__nav-primary-links a {
  color: #fff;
}

.header__nav-primary-links {
  display: flex;
  margin-left: 75px;
}

.header__nav-primary-links li {
  margin-left: 30px;
}

.header__nav-primary-links a {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
}

.header__nav-primary-links li:first-of-type {
  margin-left: 0px;
}

.header__logo {
  display: block;
  border: none;
  user-select: none;
}

.header__contacts {
  display: flex;
  align-items: center;
  margin-left: 75px;
}

.header__contacts--vertical-list {
  margin-left: 75px;
}

.header__nav .socials {
  display: none;
}

@media screen and (max-width: 1320px) {
  .header__logo {
    max-width: 180px;
  }

  .header__nav-primary-links,
  .header__contacts {
    margin-left: 50px;
  }

  .header__nav-primary-links li {
    margin-left: 20px;
  }

  .header__contacts--vertical-list {
    margin-left: 40px;
  }

  .header__top {
    justify-content: space-between;
    padding: 7px 0px 25px;
  }
}

@media screen and (max-width: 1024px) {
  .header {
    min-height: unset;
    padding-bottom: 80px;
  }

  .header__top {
    padding: 10px 20px 25px;
    box-sizing: border-box;
  }

  .header__logo {
    max-width: 94px;
  }

  .header__nav-primary-links a {
    font-size: 12px;
  }

  .header__nav-primary-links {
    margin-left: 25px;
  }

  .header__nav-primary-links li {
    margin-left: 25px;
  }

  .header__contacts {
    margin-left: 25px;
  }

  .header__contacts--vertical-list {
    margin-left: 27px;
  }

  .phone-number, .work-shedule {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .header {
    min-height: unset;
    background: linear-gradient(136deg, #050B17 37.79%, rgba(5, 11, 23, 0) 100%), url(../images/header-bg-768.jpg) no-repeat right bottom;
  }

  .header__top {
    padding: 12px 20px 0px;
  }

  .header__contacts {
    flex-direction: column;
    margin-left: 0px;
  }

  .header__nav {
    box-sizing: border-box;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 68px;
    background: #060C18;
    padding: 20px;
    min-width: 360px;
    transform: translateX(-100%);
    transition: transform 200ms ease-out, opacity 300ms linear;
    opacity: 0;
    visibility: hidden;
    z-index: 10;
  }

  .header__nav.show {
    visibility: visible;
    transform: translateX(0%);
    opacity: 1;
  }

  .header__nav-primary-links {
    margin: 0px;
    display: flex;
    flex-direction: column;
  }

  .header__nav-primary-links li {
    margin-left: 0px;
    margin-bottom: 10px;
  }

  .header__nav-primary-links li:last-of-type {
    margin-bottom: 0px;
  }

  .header__nav-primary-links a {
    font-size: 13px;
    font-weight: 600;
  }

  .header__nav .socials {
    display: flex;
    margin-top: 10px;
  }

  .header__nav .socials__link img {
    max-width: 30px;
  }

  .header__nav .drop-item::after {
    margin-top: 5px;
  }
}

@media screen and (max-width: 360px) {
  .header {
    background: linear-gradient(136deg, #050B17 37.79%, rgba(5, 11, 23, 0) 100%), url(../images/header-bg-360.jpg) no-repeat right bottom;
  }
}