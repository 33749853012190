.contacts {
  margin: 100px auto 0px;
  padding: 0px 20px;
  box-sizing: border-box;
}

.contacts-company-phone {
  font-size: 20px;
}

.contacts-company-phone,
.contacts-company-address {
  margin-bottom: 24px;
}

.contacts-company-email {
  margin-bottom: 10px;
}

.contacts-company-phone::before,
.contacts-company-email::before,
.contacts-company-address::before {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.contacts__section-title {
  text-align: left;
  margin: 30px auto 40px;
}

.contacts__requisites {
  margin: 0px auto 60px;
  color: #2b2b2b;
}

.contacts .company-info {
  font-size: 18px;
  line-height: 24px;
}

.contacts .company-info__label {
  color: #828282;
}

.contacts .footer__company-full-name {
  font-size: 18px;
  line-height: 24px;
}

.contacts .phone-feedback__input {
  color: #2b2b2b;
}

@media screen and (max-width: 1024px) {
  .contacts {
    margin: 70px auto 0px;
  }

  .contacts__requisites .company-info--double {
    flex-direction: column;
  }

  .contacts__requisites .company-info--double .company-info__text {
    max-width: 100%;
    width: 100%;
    margin-top: 5px;
  }

  .contacts__section-title {
    margin: 20px auto 30px;
  }
}

@media screen and (max-width: 768px) {
  .contacts {
    margin: 40px auto 0px;
  }

  .contacts .footer__company-full-name {
    font-size: 16px;
    line-height: 22px;
  }

  .contacts .company-info {
    font-size: 16px;
    line-height: 22px;
  }

  .contacts__requisites {
    margin: 0px auto 30px;
  }
}
