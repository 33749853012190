.product-box {
  box-sizing: border-box;
  width: 100%;
  max-width: 392px;
  margin-bottom: 40px;
}

.product-box:nth-of-type(1n) {
  margin-right: 20px;
}

.product-box:nth-of-type(3n) {
  margin-right: 0px;
}

.product-box__image-wrapper {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.product-box__image-wrapper::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: linear-gradient(179.92deg, rgba(19, 19, 19, 0) 0.07%, #131313 99.93%);;
}

.product-box__image {
  display: block;
  margin: 0px;
  width: 100%;
  max-width: 100%;
}

@media screen and (max-width: 1320px) {
  .product-box:nth-of-type(1n) {
    margin-right: 20px;
  }

  .product-box:nth-of-type(2n) {
    margin-right: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .product-box {
    max-width: 354px;
  }
}

@media screen and (max-width: 768px) {
  .product-box:nth-of-type(1n) {
    margin-right: 0px;
  }

  .product-box__desc {
    margin: 0px 0px 20px;
  }
}