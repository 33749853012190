.section-title--partners {
  margin-top: 0px;
  margin-bottom: 60px;
}

.partners {
  margin-bottom: 100px;
}

.partners__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.partners__list > img {
  display: block;
}

.partners__list > img:first-of-type {
  margin: 0px;
}

@media screen and (max-width: 1320px) {
  .section-title--partners {
    margin-bottom: 30px;
  }

  .partners__list {
    box-sizing: border-box;
    padding: 0px 30px;
  }

  .partners__inner {
    max-width: 100%;
  }

  .partners__list > img {
    width: 14%;
    max-width: 14%;
  }

  .partners {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 768px) {
  .partners {
    margin-bottom: 50px;
  }

  .partners__list {
    flex-wrap: wrap;
    max-width: 400px;
    width: 100%;
    margin: auto;
  }

  .partners__list > img {
    width: 100%;
    max-width: 50%;
    padding: 10px 14px;
    box-sizing: border-box;
  }

  .section-title--partners {
    margin-bottom: 30px;
  }
}
