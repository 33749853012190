.menu {
  position: absolute;
  top: 140px;
  left: 0px;
  width: 100%;
}

.menu__inner {
  display: flex;
  justify-content: space-between;
}

.menu__category-item {
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
}

.menu__category-groups {
  position: absolute;
  top: 34px;
  left: 0px;
  display: none;
  width: 100%;
  background-color: #14161E;
  padding: 0px 0px 42px;
  z-index: 1;
}

.menu__category-groups.show {
  display: block;
}

.menu__category-groups-inner {
  display: flex;
  flex-wrap: wrap;
}

.menu__category-group {
  margin: 0px;
}

.menu__category-link {
  color: #fff;
  font-size: 18px;
  line-height: 24px;
}

.menu__category-group {
  margin-right: 40px;
  margin-top: 16px;
}

.menu__category-group li {
  margin-bottom: 8px;
}

.menu__category-group li:last-of-type {
  margin-bottom: 0px;
}

@media screen and (max-width: 1320px) {
  .menu {
    top: 100px;
  }

  .menu__category-item {
    font-size: 13px;
  }

  .drop-item::after {
    margin-left: 6px;
  }

  .drop-item::after {
    width: 15px;
    height: 8px;
  }
}

@media screen and (max-width: 1024px) {
  .menu {
    top: 75px;
    padding: 0px 20px;
    box-sizing: border-box;
  }

  .menu__category-item {
    font-size: 12px;
    max-width: 160px;
    text-align: center;
    display: flex;
    align-items: flex-start;
    line-height: 1.4;
  }

  .menu__category-link {
    font-size: 16px;
    line-height: 22px;
  }

  .menu__category-groups {
    top: 48px;
    box-sizing: border-box;
    padding: 0px 20px 25px;
  }
}

@media screen and (max-width: 768px) {
  .menu {
    position: static;
    top: 0px;
    left: 0px;
    display: block;
    padding: 0px;
  }

  .menu__inner {
    flex-direction: column;
  }

  .menu__category-item {
    max-width: 100%;
    font-weight: 600;
    font-size: 13px;
  }

  .menu__category-wrapper {
    margin-bottom: 15px;
  }

  .menu__category-wrapper:last-child {
    margin-bottom: 20px;
  }

  .menu__category-groups {
    position: static;
    top: initial;
    left: initial;
    background: transparent;
    padding: 0px 10px 15px;
  }

  .menu__category-group {
    margin-top: 8px;
    margin-right: auto;
  }

  .menu__category-group:first-of-type {
    margin-top: 0px;
  }

  .menu__category-groups-inner {
    padding-top: 15px;
  }

  .menu__category-link {
    font-size: 14px;
    line-height: 1.3;
  }

  .menu__category-groups-inner {
    display: block;
    flex-wrap: initial;
  }
}