.service-catalog {
  padding: 70px 0px 0px;
}

.service-catalog__products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}

.service-catalog__product {
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);
  margin: 0px 10px 69px 0px;
  padding: 0px 0px 40px;
  background-color: #060c18;
  border-radius: 6px;
  overflow: hidden;
}

.service-catalog__product::after {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: linear-gradient(0deg, #060C18 45%, rgba(6, 12, 24, 0) 100%);
}

.service-catalog__product:nth-child(1n + 3) {
  width: calc(33.3% - 10px);
}

.service-catalog__product > img {
  max-width: 100%;
  width: 100%;
  height: 355px;
  object-fit: cover;
}

.section-title--service-catalog {
  margin-top: 0px;
  margin-bottom: 40px;
}

.service-catalog__product-text {
  position: relative;
  padding: 0px 40px;
  color: #fff;
  line-height: 24px;
  font-weight: 400;
  z-index: 1;
}

.service-catalog__product-text p {
  margin: 0px auto 10px;
}

.service-catalog__product-title {
  font-size: 22px;
  line-height: 1.4;
  font-weight: 600;
  margin: 0px 0px 20px;
  max-width: 370px;
}

.service-catalog__btn-wrapper {
  margin: auto 0px 0px;
  padding: 0px 40px;
}

.common-btn--service-catalog {
  position: relative;
  z-index: 1;
  margin: 20px 0px 0px;
  opacity: 0.4;
  transition: opacity 300ms linear;
}

.common-btn--service-catalog:focus,
.common-btn--service-catalog:hover {
  opacity: 1;
}

@media screen and (max-width: 1320px) {
  .service-catalog__product:nth-of-type(2),
  .service-catalog__product:nth-child(5) {
    margin-right: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .service-catalog__product {
    margin: 0px 10px 20px 0px;
  }

  .service-catalog__product-text,
  .service-catalog__btn-wrapper {
    padding: 0px 20px;
  }

  .service-catalog__product-title {
    max-width: 320px;
    font-size: 18px;
  }

  .common-btn--service-catalog {
    opacity: 1;
  }

  .service-catalog {
    padding: 70px 0px 50px;
  }

  .service-catalog__products {
    padding: 0px 20px;
  }

  .service-catalog__product > img {
    height: 336px;
  }

  .service-catalog {
    padding: 50px 0px;
  }

  .section-title--service-catalog {
    margin-bottom: 25px;
  }

  .service-catalog__products {
    display: block;
  }

  .service-catalog__product {
    width: 100%;
    max-width: 360px;
    margin: 0px auto 20px;
  }

  .service-catalog__product:nth-of-type(2), .service-catalog__product:nth-child(5) {
    margin-right: auto;
  }

  .service-catalog__product:nth-child(1n + 3) {
    width: 100%;
  }

  .common-btn--service-catalog {
    margin: 13px 0px 0px;
    font-size: 12px;
  }

  .service-catalog__product {
    padding-bottom: 30px;
  }

  .service-catalog__product-title {
    margin-bottom: 10px;
  }
}