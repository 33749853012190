.socials {
  display: flex;
  flex-wrap: wrap;
}

.socials__link {
  margin-top: 15px;
  margin-right: 30px;
}

.socials__link:last-of-type {
  margin-right: 0px;
}

@media screen and (max-width: 768px) {
  .socials__link {
    margin-right: 20px;
  }
}