.our-works {
  margin: 70px auto 65px;
}

.about-page--our-works {
  max-width: 630px;
}

.our-works__title {
  margin-bottom: 50px;
}

@media screen and (max-width: 1320px) {
  .our-works__title {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .our-works {
    margin: 50px auto;
  }
}