.benefits {
  display: flex;
}

.benefits__item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 76px 20px 51px;
  font-size: 20px;
  line-height: 26px;
}

.benefits__item > div {
  display: block;
  margin: 0px auto auto;
  max-width: 370px;
}

.benefits__item--first {
  background: #060c18;
}

.benefits__item--second {
  background: #57310d;
}

.benefits__item--third {
  background: #f5f5f5;
  color: #2b2b2b;
}

.benefits__icon {
  display: block;
  margin: 0px auto 47px;
}

@media screen and (max-width: 1320px) {
  .benefits__item {
    font-size: 18px;
    line-height: 24px;
    box-sizing: border-box;
    padding: 60px 20px 50px;
  }

  .benefits__icon {
    margin: 0px auto 32px;
  }
}

@media screen and (max-width: 1024px) {
  .benefits__item {
    padding: 30px 13px;
    font-size: 16px;
  }

  .benefits__icon {
    max-width: 60px;
    margin: 0px auto 20px;
  }
}

@media screen and (max-width: 768px) {
  .benefits {
    flex-direction: column;
  }
}