.feedback-info {
  padding-bottom: 80px;
}

.feedback-info__inner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.feedback-info__title {
  box-sizing: border-box;
  text-align: left;
  padding-right: 50px;
  width: 100%;
  max-width: 520px;
}

.feedback-form {
  width: 100%;
  max-width: 59%;
}

.feedback-form__text {
  font-size: 18px;
  line-height: 24px;
  max-width: 85%;
}

.feedback-form__inner {
  display: flex;
  align-items: flex-start;
  margin-top: 35px;
}

.feedback-form__field {
  margin-right: 32px;
}

.feedback-form__field--last {
  margin-right: 0px;
}

.feedback-form__input {
  width: 100%;
  max-width: 230px;
  display: block;
  box-sizing: border-box;
  padding: 15px 10px 14px 20px;
  font-size: 18px;
  line-height: 24px;
  border-radius: 30px;
}

.feedback-form__input,
.feedback-form__input:focus {
  border: 1px solid #bdbdbd;
  outline: none;
}

.feedback-form__input::placeholder {
  color: #bdbdbd;
}

.feedback-form__submit {
  width: 100%;
  padding: 15px 50px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  height: 54px;
}

.mail-contact {
  width: 100%;
  margin-top: 60px;
  text-align: center;
  font-size: 30px;
  line-height: 1.3;
  font-weight: 600;
}

.mail-contact__address {
  display: block;
  font-size: 40px;
  font-weight: 700;
  background: linear-gradient(270deg, #57310D 0%, #CB7921 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-top: 10px;
}

@media screen and (max-width: 1320px) {
  .feedback-info__title {
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 1.4;
  }

  .feedback-form {
    max-width: 59%;
  }

  .feedback-info__title {
    max-width: 400px;
  }

  .feedback-form__field {
    margin-right: 12px;
  }

  .feedback-form__input {
    font-size: 16px;
  }

  .feedback-form__field--last {
    margin-right: 0px;
  }

  .mail-contact {
    font-size: 26px;
  }

  .mail-contact__address {
    font-size: 35px;
  }

  .feedback-info {
    padding-bottom: 60px;
  }

  .feedback-info__inner {
    justify-content: center;
  }
}

@media screen and (max-width: 1024px) {
  .feedback-info {
    padding: 0px 20px 60px;
  }

  .feedback-info__title {
    max-width: 100%;
    padding: 0px;
    margin-bottom: 10px;
  }

  .feedback-form {
    max-width: 100%;
  }

  .feedback-form__inner {
    justify-content: space-between;
  }

  .feedback-form__text {
    max-width: 95%;
  }

  .mail-contact {
    font-size: 28px;
  }

  .mail-contact__address {
    font-size: 36px;
  }
}

@media screen and (max-width: 768px) {
  .feedback-form__inner {
    flex-direction: column;
  }

  .feedback-info {
    padding: 0px 20px 50px;
  }

  .feedback-form__field {
    margin: 0px auto 15px;
    width: 100%;
  }

  .feedback-form__input {
    max-width: 100%;
  }

  .feedback-form__inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 460px;
  }

  .mail-contact {
    font-size: 22px;
    margin-top: 40px;
  }

  .mail-contact__address {
    font-size: 28px;
  }
}
