body {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  color: #2b2b2b;
}

a {
  text-decoration: none;
}

ul, ol {
  list-style: none;
  padding-left: 0px;
}

button {
  cursor: pointer;
  border: none;
}

.layout {
  max-width: 1920px;
  margin: 0px auto;
  min-width: 360px;
}

.normal-content-size {
  width: 100%;
  max-width: 1280px;
  margin: auto;
}

.email-contact,
.phone-number,
.work-shedule {
  color: #fff;
}

.email-contact {
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}

.email-contact::before {
  content: "";
  display: inline-block;
  width: 26px;
  height: 20px;
  background: url('../images/mail.png') no-repeat center / cover;
  vertical-align: middle;
  margin-right: 12px;
}

.phone-number,
.work-shedule {
  font-size: 18px;
  line-height: 1.3;
}

.phone-number::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url('../images/phone.png') no-repeat center;
  vertical-align: middle;
  margin-right: 4px;
  margin-bottom: 3px;
  background-size: contain;
}

.work-shedule::before {
  content: "";
  display: inline-block;
  width: 19px;
  height: 19px;
  background: url('../images/work-shedule.svg') no-repeat center;
  vertical-align: middle;
  margin-right: 6px;
  background-size: contain;
}

.vertical-contact-list {
  display: flex;
  flex-direction: column;
}

.vertical-contact-list .phone-number {
  margin-bottom: 8px;
}

.read-more-btn {
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #2b2b2b;
  transition: color 100ms linear;
}

.read-more-btn--white {
  color: #fff;
}

.read-more-btn:active {
  color: #59320e;
}

.read-more-btn:focus,
.read-more-btn:hover {
  color: #cb7921;
}


.read-more-wrapper--right-align {
  text-align: right;
}

.read-more-btn::after {
  content: '';
  width: 66px;
  height: 46px;
  display: inline-block;
  vertical-align: middle;
  background: url('../images/readmore-arrow.png') no-repeat center / contain;
  margin-left: 10px;
}

.read-more-btn--white::after {
  background: url('../images/white-arrow-and-circle.svg') no-repeat center / contain;
}

.read-more-btn:active::after {
  background: url('../images/readmore-arrow-active.png') no-repeat center / contain;
}

.read-more-btn:focus::after,
.read-more-btn:hover::after {
  background: url('../images/readmore-arrow-hovered.png') no-repeat center / contain;
}

.section-title {
  font-size: 40px;
  line-height: 52px;
  font-weight: 700;
  text-align: center;
  margin-top: 0px;
}

.common-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background: linear-gradient(270deg, #57310D 0%, #CB7921 100%);
  border-radius: 30px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.common-btn__bg {
  display: block;
  position: absolute;
  background: linear-gradient(270deg, #391300 0%, #AD5B03 100%);
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-radius: inherit;
  opacity: 0;
  transition: opacity 300ms linear;
}

.common-btn__text {
  position: relative;
  z-index: 1;
}

.common-btn:focus .common-btn__bg,
.common-btn:hover .common-btn__bg {
  opacity: 1;
}

.common-btn--success-form-send {
  padding: 15px 50px;
  font-size: 18px;
}

.form-input {
  padding: 15px 10px 14px 20px;
  font-size: 14px;
  background: transparent;
  border: 2px solid #BDBDBD;
  border-radius: 30px;
}

.phone-feedback {
  font-size: 30px;
  line-height: 39px;
  font-weight: 700;
}

.phone-feedback--footer {
  max-width: 460px;
  margin-top: -20px;
}

.phone-feedback__field {
  width: 200px;
  max-width: 100%;
  margin-right: 16px;
}

.phone-feedback__field--last {
  margin-right: 0px;
}

.phone-feedback__input {
  display: block;
  box-sizing: border-box;
  font-weight: 400;
  width: 100%;
  color: #fff;
}

.phone-feedback__input::placeholder {
  color: #BDBDBD;
}

.phone-feedback__title {
  margin-top: 0px;
  margin-bottom: 20px;
  color: #fff;
  font-size: 30px;
}

.phone-feedback__row {
  display: flex;
}

.phone-feedback__submit {
  width: 100%;
  font-size: 14px;
  padding: 0px;
  height: 47px;
}

.parsing-data-agree {
  display: flex;
  color: #2B2B2B;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.3;
  margin: 8px 0px 0px;
}

.parsing-data-agree--footer {
  color: #fff;
}

.parsing-data-agree__native-checkbox {
  display: none;
}

.parsing-data-agree__custom-checkbox {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-right: 5px;
  background: linear-gradient(270deg, #bebebe 0%, #8b8b8b 100%);
  flex-shrink: 0;
}

.parsing-data-agree__native-checkbox:checked ~ .parsing-data-agree__custom-checkbox {
  background: url('../images/agree-check.svg') no-repeat center center / 80%, linear-gradient(270deg, #57310D 0%, #CB7921 100%);
}

.parsing-data-agree__text {
  margin: 0px;
}

.drop-item {
  display: inline-block;
}

.drop-item::after {
  content: '';
  display: inline-block;
  width: 17px;
  height: 10px;
  margin-left: 8px;
  background: url(../images/arrow-down.svg) no-repeat center / contain;
  vertical-align: middle;
  margin-bottom: 4px;
  flex-shrink: 0;
}

.drop-item.dropped,
.drop-item:focus,
.drop-item:hover {
  color: #CB7921;
}

.drop-item:focus::after,
.drop-item:hover::after {
  background-image: url(../images/arrow-down-active.svg);
}

.drop-item.dropped::after {
  background-image: url(../images/arrow-up-active.svg);
}

.company-info {
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  margin-bottom: 8px;
}

.company-info--double {
  display: flex;
}

.company-info--double .company-info__text {
  max-width: 50%;
  width: 100%;
}

.company-info__label {
  color: #B4B6BA;
  font-weight: 400;
  display: inline-block;
  margin-right: 6px;
}

.hamb {
  display: none;
  width: 16px;
  height: 14px;
  margin-right: 26px;
}

.hamb__close-icon {
  display: none;
}

.hamb.show .hamb__close-icon {
  display: block;
}

.hamb.show .hamb__default-icon {
  display: none;
}

.company-email {
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}

.company-email--menu,
.company-address--menu {
  margin-top: 20px;
}

.company-email::before {
  content: '';
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url('../images/gradient-mail.png') no-repeat center / contain;
  vertical-align: middle;
  margin-right: 6px;
  margin-top: -2px;
}

.company-phone {
  display: inline-block;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
}

.company-phone::before {
  content: '';
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url('../images/gradient-phone.png') no-repeat center / contain;
  vertical-align: middle;
  margin-right: 6px;
  margin-top: -2px;
}

.company-address {
  display: flex;
  color: #fff;
  max-width: 320px;
  font-size: 18px;
}

.company-address::before {
  content: '';
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url('../images/gradient-location.png') no-repeat center / contain;
  vertical-align: middle;
  margin-right: 6px;
  margin-top: -2px;
  flex-shrink: 0;
}

.company-email--menu,
.company-address--menu,
.company-phone--menu {
  display: none;
}

.company-phone--menu {
  margin-top: 50px;
}

.company-phone--footer {
  font-size: 20px;
  margin-bottom: 24px;
  margin-top: 40px;
}

.company-address--footer {
  font-size: 18px;
  margin-bottom: 24px;
  line-height: 23px;
}

.company-email--footer {
  margin-bottom: 15px;
}

.company-phone--footer::before,
.company-address--footer::before,
.company-email--footer::before {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.link {
  color: #fff;
  transition: color 80ms linear;
}

.link:focus,
.link:hover {
  color: #CB7921;
}

.section-title--product {
  margin-bottom: 40px;
}

.about-page {
  margin-top: 55px;
}

.about-page-title {
  font-size: 68px;
  line-height: 83px;
  margin: 0px auto 30px;
  color: #fff;
}

.about-page-desc {
  font-size: 24px;
  line-height: 32px;
  max-width: 900px;
  margin: 0px 0px 15px;
  color: #fff;
}

.about-page--type-2 {
  margin-top: 65px;
}

.about-page--type-2 .about-page-desc {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
}

.form-error-message {
  position: fixed;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  background: #000;
  color: #fff;
  padding: 20px 40px;
  line-height: 1.4;
  border: 6px double white;
  z-index: 10;
  text-align: center;
  min-width: 250px;
  max-width: 51%;
}

.form-error-message__head {
  display: block;
  margin-bottom: 6px;
  color: #f6f041;
}

.form-error-message__btn {
  background: #fff;
  font-size: 18px;
  padding: 5px 21px;
  cursor: pointer;
  border: 4px double #000;
  transition: border 300ms linear;
}

.form-error-message__btn:focus,
.form-error-message__btn:hover {
  border: 4px double #fff;
}

.form-error + .form-error {
  margin-top: 10px;
}

.form-success-message {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  padding: 25px 40px 20px;
  background: #fff;
  font-size: 21px;
  line-height: 1.5;
  border: 2px solid #b96d1e;
  border-radius: 5px;
  z-index: 10;
  box-shadow: 0px 0px 10px #aaa;
  text-align: center;
  min-width: 250px;
  max-width: 51%;
}

.copy-it {
  display: inline-block;
  cursor: pointer;
}

.copy-it::after {
  content: "";
  display: inline-block;
  width: 34px;
  height: 34px;
  background: url('../images/copy-icon.svg') no-repeat center center / contain;
  vertical-align: middle;
  margin-top: -2px;
  margin-left: 22px;
}

.clipboard-message {
  position: fixed;
  z-index: 100;
  bottom: 30px;
  right: 50px;
  color: #fff;
  background: #2b2b2b;
  padding: 20px 30px;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px #848484;
  animation: clipboard 3s forwards;
}

@keyframes clipboard {
  0% {
    opacity: 0;
    transform: translateY(40px);
  } 50% {
    opacity: 1;
    transform: translateY(0px);
  } 100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

@media screen and (max-width: 1320px) {
  .normal-content-size {
    max-width: 1000px;
  }

  .email-contact::before {
    width: 24px;
    height: 18px;
    margin-right: 9px;
  }

  .email-contact {
    font-size: 18px;
  }

  .phone-number,
  .work-shedule {
    font-size: 17px;
  }

  .section-title {
    font-size: 32px;
    line-height: 38px;
  }

  .phone-feedback__title {
    font-size: 26px;
  }

  .phone-feedback--footer {
    margin-top: 30px;
  }

  .about-page-title {
    font-size: 63px;
    line-height: 73px;
  }
}

@media screen and (max-width: 1024px) {
  .section-title {
    font-size: 36px;
  }

  .normal-content-size {
    max-width: 768px;
  }

  .email-contact {
    font-size: 14px;
  }

  .email-contact::before {
    width: 16px;
    height: 12px;
    margin-right: 6px;
  }

  .drop-item::after {
    margin-top: 2px;
    margin-bottom: 0px;
  }

  .phone-feedback__title {
    font-size: 28px;
  }

  .company-phone--footer {
    margin-top: 10px;
  }

  .about-page-title {
    font-size: 44px;
    line-height: 54px;
  }

  .about-page-desc {
    font-size: 18px;
    line-height: 24px;
  }

  .about-page--type-2 .about-page-desc {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 768px) {
  .work-shedule {
    display: none;
  }

  .vertical-contact-list .phone-number {
    margin-bottom: 0px;
  }

  .read-more-btn {
    font-size: 14px;
  }

  .section-title {
    font-size: 28px;
  }

  .hamb {
    display: block;
  }

  .company-info {
    font-size: 14px;
  }

  .company-info__label {
    margin-right: 4px;
  }

  .phone-feedback__title {
    font-size: 24px;
  }

  .phone-feedback__field {
    width: 100%;
    max-width: 100%;
    margin: 0px auto 15px;
  }

  .phone-feedback__field--last {
    margin-bottom: 0px;
  }

  .phone-feedback__row {
    flex-direction: column;
  }

  .parsing-data-agree--footer {
    justify-content: center;
    margin-bottom: 0px;
  }

  .parsing-data-agree {
    justify-content: center;
    margin: 9px 0px 0px;
  }

  .phone-number::before {
    margin-right: 3px;
    width: 16px;
    height: 16px;
  }

  .company-email--menu,
  .company-phone--menu {
    display: inline-block;
  }

  .company-address--menu {
    display: flex;
  }

  .company-phone--footer {
    font-size: 18px;
  }

  .company-phone--footer::before,
  .company-address--footer::before,
  .company-email--footer::before {
    width: 26px;
    height: 26px;
    margin-right: 8px;
  }

  .section-title--product {
    margin-bottom: 25px;
  }

  .about-page {
    margin-top: 30px;
  }

  .about-page-title {
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 25px;
  }

  .about-page-desc,
  .about-page--type-2 .about-page-desc {
    font-size: 16px;
    line-height: 21px;
  }

  .form-error-message,
  .form-success-message {
    top: 10%;
    padding: 20px;
  }

  .form-success-message {
    font-size: 20px;
  }

  .clipboard-message {
    right: 20px;
  }
}