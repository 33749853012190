.product-catalog {
  padding-bottom: 80px;
}

.product-catalog__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product-box__title {
  font-size: 20px;
  line-height: 26px;
  margin: 20px 0px 10px;
}

.product-box__desc {
  font-size: 16px;
  line-height: 1.3;
  font-weight: 400;
  margin: 0px 0px 25px;
}

@media screen and (max-width: 1320px) {
  .product-catalog {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 768px) {
  .product-catalog__list {
    box-sizing: border-box;
    padding: 0px 20px;
  }
}