.service {
  padding: 100px 30px 50px;
  display: flex;
  align-items: flex-start;
}

.service__image {
  display: block;
  border-radius: 5px;
  max-width: 100%;
  margin-right: 20px;
}

.service__title {
  font-size: 40px;
  line-height: 52px;
  margin: 0px auto 40px;
}

.service__content {
  max-width: 630px;
  font-size: 18px;
  line-height: 24px;
}

.service__content > p {
  margin-bottom: 30px;
}

.service__exp {
  display: flex;
  justify-content: space-between;
  font-size: 32px;
  line-height: 42px;
  font-weight: 500;
  text-align: center;
}

.service__exp > div span {
  display: block;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  margin-top: 5px;
}

.service__read-more-btn {
  margin-top: 50px;
}

@media screen and (max-width: 1700px) {
  .service__image {
    width: 100%;
    max-width: 760px;
  }
}

@media screen and (max-width: 1320px) {
  .service {
    padding: 70px 30px 40px;
  }

  .service__image {
    max-width: 440px;
  }

  .service__content {
    max-width: 100%;
  }

  .service__title {
    font-size: 33px;
    line-height: 1.2;
    margin: 0px auto 20px;
  }

  .service__content {
    font-size: 17px;
  }

  .service__exp {
    font-size: 25px;
    line-height: 36px;
    padding: 0px 20px;
  }

  .service__exp > div span {
    font-size: 17px;
    line-height: 20px;
    margin-top: 1px;
  }
}

@media screen and (max-width: 1024px) {
  .service {
    padding: 60px 20px 40px;
  }

  .service__image {
    display: none;
  }

  .service__title {
    font-size: 36px;
  }

  .service__title br {
    display: none;
  }

  .service__content {
    font-size: 18px;
    line-height: 24px;
  }

  .service__exp {
    margin: 0px auto;
    font-size: 32px;
    padding: 0px;
  }

  .service__exp > div span {
    font-size: 18px;
    margin-top: 8px;
  }

  .service__exp > div {
    width: 33%;
  }

  .service__read-more-btn {
    margin-top: 40px;
  }
}

@media screen and (max-width: 768px) {
  .service {
    padding: 50px 20px;
  }

  .service__title {
    font-size: 28px;
  }

  .service__content {
    font-size: 15px;
  }

  .service__exp {
    font-size: 22px;
    max-width: 360px;
  }

  .service__exp > div span {
    font-size: 12px;
    line-height: 1.3;
    margin-top: 0px;
  }

  .service__exp > div {
    width: auto;
  }

  .service__read-more-btn {
    margin-top: 30px;
  }
}